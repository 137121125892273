import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import {
  Box,
  Button,
  Circle,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  Image,
} from "@chakra-ui/react";
import logo from "../assets/logo.png";
import { sizing } from "../styles/sizing";
import Ask from "./Ask";
import defaultProfilePic from "../assets/profileAnonPicInverse.png";
import axios from "axios";
import { getStatsigClient } from "../utils/statsig";

const AskLink = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const askeeUniqueId = window.location.pathname.split("/")[2];
  const [receiverDisplayName, setReceiverDisplayName] = useState(null);
  console.log(`askeeUniqueId: ${askeeUniqueId}`);
  const onQuestion = () => {
    onOpen();
  };

  useEffect(() => {
    axios
      .post(`/api/getAskeeUser`, { uniqId: askeeUniqueId })
      .then((response) => {
        setReceiverDisplayName(response.data.user.displayName);
      });
  }, []);

  useEffect(() => {
    if (authState.isAuthenticated && authState.uniqId === askeeUniqueId) {
      console.log(
        `should redirect to profile page because authState.uniqId: ${authState.uniqId} and askeeUniqueId: ${askeeUniqueId}`
      );
      navigate("/", { state: { selectedTab: 2 } });
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    getStatsigClient().logEvent("view_external_profile_page");
  }, []);

  return (
    <Flex
      direction="column"
      width="100%"
      p={`${sizing.horizontalGuttersPx}px`}
      alignItems="center"
    >
      <Flex
        width="100%"
        maxW={`${sizing.maxAppWidthPx}px`}
        position="relative"
        justify="center"
        align="center"
        mt={{ base: "16px", md: "32px", lg: "32px" }}
        mb="32px"
      >
        <Box
          as="a"
          href="/"
          target="_self"
          rel="noopener noreferrer"
          display="inline-block"
          position="absolute"
          left="0"
        >
          <Box
            as="img"
            src={logo}
            alt="dots logo"
            height={{ base: "24px", md: "32px", lg: "32px" }}
            width="auto"
            cursor="pointer"
            className="transition-opacity hover:opacity-80"
          />
        </Box>
        {authState.isAuthenticated && (
          <Flex
            as={Link}
            to="/"
            position="absolute"
            right="0"
            align="center"
            gap={2}
            cursor="pointer"
            transition="opacity 0.2s"
            borderRadius="30px"
            border="0.25px solid #545454"
            _hover={{ opacity: 0.8 }}
            p={2}
            _focus={{ outline: "none", boxShadow: "none" }}
          >
            <Box
              borderRadius="full"
              width={{ base: "30px", md: "30px", lg: "33px" }}
              height={{ base: "30px", md: "30px", lg: "33px" }}
              overflow="hidden"
            >
              <Image
                src={authState.profilePic || defaultProfilePic}
                alt="Profile"
                width="100%"
                height="100%"
                objectFit="cover"
              />
            </Box>
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight="bold"
              color="white"
            >
              Home
            </Text>
          </Flex>
        )}
      </Flex>
      <Box maxW={`${sizing.maxAppWidthPx}px`} width="100%">
        <Ask isInternal={false} onQuestion={onQuestion} />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter='blur(5px)' />
        <ModalContent bg="#1C1C1C" color="white" borderRadius="30px">
          <ModalHeader textAlign="center" color="white" fontSize="2xl">
            Create a Dots Profile to Ask <br></br>
            <Text as="span" color="#FF0050">{receiverDisplayName}</Text> Questions!
            <Text 
              as="h2" 
              color="white" 
              fontSize="sm" 
              mt={4} 
              fontWeight="normal"
            >
              Dots is a private network that allows you to connect with anyone & see who they really are
            </Text>
          </ModalHeader>
          <ModalBody>
            <VStack align="start" spacing={4} ml="20px">
              <HStack>
                <Circle size="20px" bg="white" color="#1C1C1C">
                  <Text fontSize="sm" fontWeight="bold">
                    1
                  </Text>
                </Circle>
                <Text fontSize="sm" color="white">Ask unlimited questions - for free</Text>
              </HStack>
              <HStack>
                <Circle size="20px" bg="white" color="#1C1C1C">
                  <Text fontSize="sm" fontWeight="bold">
                    2
                  </Text>
                </Circle>
                <Text fontSize="sm" color="white">Get instant responses</Text>
              </HStack>
              <HStack>
                <Circle size="20px" bg="white" color="#1C1C1C">
                  <Text fontSize="sm" fontWeight="bold">
                    3
                  </Text>
                </Circle>
                <Text fontSize="sm" color="white">Get your own profile - show the real you</Text>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Button
              as={Link}
              to="/signup"
              state={{ onboardingRewardUniqueId: askeeUniqueId }}
              colorScheme="red"
              backgroundColor="#FF0050"
              _hover={{ bg: "#FF3371" }}
              borderRadius="30"
              size="lg"
            >
              Create Your Account
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AskLink;
