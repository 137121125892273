import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import InboxItem from "./InboxItem";
import { getStatsigClient } from "../utils/statsig";
import OnboardingProgressIndicator from "./OnboardingProgressIndicator";
import { setAuthInfo } from "../store/slices/authSlice";
import { sizing } from "../styles/sizing";
import { Image } from "@chakra-ui/react";
import defaultProfilePic from "../assets/profileAnonPicInverse.png";
import { AddIcon } from "@chakra-ui/icons";
import { shareProfile } from "../utils/shareUtils";
import { useInView } from "react-intersection-observer";
import {FeedItem, InteractedProfile} from "../../../server/types/apiTypes";

const HomeTab = () => {
  const authState = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const userId = authState.userId; // Get userId from authentication state
  const [messageCards, setMessageCards] = useState<FeedItem[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [recentProfiles, setRecentProfiles] = useState<InteractedProfile[]>([]);
  const toast = useToast();
  const [hasMore, setHasMore] = useState(true);
  const { ref, inView } = useInView();

  // Add this before the useEffect hooks
  const fetchMoreFeedItems = async () => {
    try {
      setIsLoading(true);
      let cursor = undefined;
      if (messageCards.length !== 0) {
        const lastItem = messageCards[messageCards.length - 1];
        cursor = lastItem.createdAt;
      }
      const response = await axios.get(`/api/loadMoreFeedItems`, {
        params: {
          cursor: cursor,
        },
      });

      if (response.status !== 200) {
        throw new Error(
          `Network response was not ok, status: ${response.status}`
        );
      }

      if (response.data.length === 0) {
        setHasMore(false);
        return;
      }

      return response.data
    } catch (error) {
      console.error("Error fetching more items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch message cards from the database
  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      Promise.all([fetchMoreFeedItems()]).then(([newItems]) => {
        setMessageCards(newItems);
        setIsLoading(false);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (messageCards.length > 0) {
      (async () => {
        const profileRes = await axios.get(`/api/uniqueInteractedUsers`);
        if (profileRes.status === 200) {
          setRecentProfiles(profileRes.data);
        }
      })();
    }
  }, [messageCards, userId]);

  const handleAddNoteClick = async () => {
    getStatsigClient().logEvent("clicked_add_note");
    navigate("/addNote");
  };

  useEffect(() => {
    if (authState.justCompletedOnboardingTutorial) {
      console.log("Just completed onboarding tutorial, navigating to askee");
      (async () => {
        if (authState.onboardingRewardUserId) {
          console.log("Getting askee user");
          const userRes = await axios.post(`/api/getAskeeUser`, {
            userId: authState.onboardingRewardUserId,
          });
          if (userRes.data.user) {
            console.log("Navigating to askee user profile");
            navigate(`/profile/${userRes.data.user.uniqId}`);
            dispatch(
              setAuthInfo({
                justCompletedOnboardingTutorial: false,
              })
            );
          }
        } else {
          console.log("Navigating to profile tab");
          dispatch(
            setAuthInfo({
              justCompletedOnboardingTutorial: false,
              currentTabIndex: 2,
            })
          );
        }
      })();
    }
  }, [messageCards]);

  const handleShareAndCopy = async () => {
    const userId = authState.userId;
    if (!userId) {
      console.error("No userId found");
      return;
    }
    getStatsigClient().logEvent("click_share_link");

    if (authState.uniqId) {
      await shareProfile(authState.uniqId, toast);
    }
  };

  useEffect(() => {
    const loadMore = async () => {
      if (inView && hasMore && !isLoading && messageCards.length > 0) {
        const newItems = await fetchMoreFeedItems();
        if (newItems) {
          setMessageCards((prev) => {
            const updatedCards = [...prev, ...newItems];
            return updatedCards;
          });
        }
      }
    };

    loadMore();
  }, [inView]);

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Flex
        direction="column"
        alignItems="center"
        gap={4}
        mb="100px"
        width="100%"
        maxW="100%"
        mx="auto"
        overflowY="auto"
        overflowX="hidden"
        flex="1"
      >
        {!authState.isOnBoardingTutorialComplete && (
          <OnboardingProgressIndicator
            currentStep={authState.numNotesOrQuestionsAnswered}
          />
        )}
        {authState.isOnBoardingTutorialComplete && (
          <Flex direction="column" width="100%" alignItems="center">
            <Box
              width="100%"
              maxW="calc(100% - 16px)"
              overflowX="auto"
              height="110px"
              display="flex"
              alignItems="center"
              css={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
              }}
              mb={2}
            >
              <Flex gap={2} width="max-content" px={1}>
                <Box
                  cursor="pointer"
                  transition="transform 0.2s"
                  _hover={{ transform: "scale(1.05)" }}
                  p={1}
                  onClick={
                    authState.isOnBoardingTutorialComplete
                      ? handleShareAndCopy
                      : undefined
                  }
                >
                  <Flex direction="column" align="center">
                    <Box
                      borderRadius="full"
                      width="70px"
                      height="70px"
                      overflow="hidden"
                      bg="#FF0050"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <AddIcon w={6} h={6} color="white" />
                    </Box>
                    <Text
                      fontSize="small"
                      color="white"
                      mt={1}
                      textAlign="center"
                      noOfLines={1}
                    >
                      Share
                    </Text>
                  </Flex>
                </Box>
                {recentProfiles.map((profile) => (
                  <Box
                    key={profile.userId}
                    as={Link}
                    to={`/profile/${profile.uniqueId}`}
                    cursor="pointer"
                    transition="transform 0.2s"
                    _hover={{ transform: "scale(1.05)" }}
                    p={1}
                    onClick={() => {
                      getStatsigClient().logEvent(
                        "click_recent_profile",
                        undefined,
                        {
                          profileId: profile.userId || "",
                        }
                      );
                    }}
                  >
                    <Flex direction="column" align="center">
                      <Box
                        borderRadius="full"
                        width="70px"
                        height="70px"
                        overflow="hidden"
                      >
                        <Image
                          src={profile.profilePic || defaultProfilePic}
                          alt={profile.displayName || "Profile"}
                          width="100%"
                          height="100%"
                          objectFit="cover"
                        />
                      </Box>
                      <Text
                        fontSize="small"
                        color="white"
                        mt={1}
                        textAlign="center"
                        noOfLines={1}
                      >
                        {profile.displayName?.split(" ")[0] || "Profile"}
                      </Text>
                    </Flex>
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        )}
        {messageCards.map((card) => (
          <InboxItem
            key={card.id}
            askerName={
              card.askerUserId === authState.userId
                ? "You"
                : card.askerName
            }
            askerProfilePic={card.askerProfilePic}
            title={card.content}
            isViewed={card.seenStatus}
            isReplied={card.feedbackSubmitted ?? false}
            isNote={card.isNote}
            id={card.id}
            isNuxQuestion={card.isNuxQuestion ?? false}
            askerId={card.askerUserId}
            receiverName={
              card.receiverUserId === card.askerUserId
                ? "Yourself"
                : card.receiverUserId === authState.userId
                  ? "You"
                  : card.receiverDisplayName
            }
          />
        ))}

        <Box ref={ref} p={4} height="60px">
          {hasMore && isLoading && (
            <Center>
              <Spinner
                thickness="4px"
                speed="0.75s"
                emptyColor="#1c1c1c"
                color="#FF0050"
                size="md"
              />
            </Center>
          )}
        </Box>
      </Flex>
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        bg="#1c1c1c"
        p={4}
        textAlign="center"
        width="100%"
        maxWidth="100vw"
      >
        <Button
          width="100%"
          maxW={sizing.maxAppContentWidthPx}
          mx="auto"
          onClick={handleAddNoteClick}
          backgroundColor="#FF0050"
          color="white"
          height={{ base: "56px", md: "58px", lg: "60px" }}
          borderRadius="30px"
          _hover={{ bg: "#ef004b" }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          Improve Your Profile
        </Button>
      </Box>
    </div>
  );
};

export default HomeTab;
