import {ChangeEvent, useEffect, useState} from "react";
import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import defaultProfilePic from "../assets/profileAnonPicInverse.png";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getStatsigClient } from "../utils/statsig";
import {
  setAuthInfo,
} from "../store/slices/authSlice";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { sizing } from "../styles/sizing";
import { AutoResizeTextarea } from "./AutoResizeTextarea";

function AddOrUpdateNote() {
  const { noteId } = useParams();
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [newNoteContent, setNewNoteContent] = useState("");
  const toast = useToast();
  const dispatch = useAppDispatch();

  const profilePic = authState.profilePic || defaultProfilePic;

  getStatsigClient()?.logEvent("view_add_note_page");

  useEffect(() => {
    try {
      if (!noteId) {
        return;
      }
      axios.get(`/api/notes/${noteId}`).then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to create note");
        }
        setNewNoteContent(response.data.content);
      });
    } catch (e) {
      console.error(e);
    }
  }, [noteId]);

  const handleSaveNote = async () => {

    try {
      if (newNoteContent.length === 0) {
        toast({
          title: "Failed to save note",
          description: "Your note cannot be empty",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom",
          variant: "subtle",
          colorScheme: "red",
        });
        return;
      }

      let response = null;
      if (noteId) {
        response = await axios.patch(`/api/notes/${noteId}`, {
          content: newNoteContent,
        });
      } else {
        // Prepare the new note object based on the Sequelize model
        const newNote = {
          userId: authState.userId, // the user signed in and userId from localstorage,
          relatedAskId: null,
          //relatedOnboardingQuestionId: null,
          content: newNoteContent, // Ensure this variable contains the note content
          // embedding: embeddingData.embedding, handled in backend
          embeddingModel: null,
          tokenCount: null,
          tokenCost: null,
          status: "ENABLED",
          isArchived: false,
          createDate: new Date().toISOString(),
          noteType: "NOTE",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        response = await axios.post(`/api/notes`, newNote);
      }

      if (response.status === 200) {
        setTimeout(() => {
          setNewNoteContent("");
          getStatsigClient().logEvent("add_note", newNoteContent);
          if (!authState.isOnBoardingTutorialComplete) {
            dispatch(
              setAuthInfo({
                numNotesOrQuestionsAnswered:
                  authState.numNotesOrQuestionsAnswered + 1,
              })
            );
          }
          if (response.data.justCompletedOnboardingTutorial) {
            dispatch(
              setAuthInfo({
                currentTabIndex: 2,
                isOnBoardingTutorialComplete: true,
                justCompletedOnboardingTutorial: true,
              })
            );
            toast({
              title: "Congratulations",
              description: "You can now ask your Dot question!",
              status: "success",
              duration: 3000,
              position: "top",
              isClosable: true,
              render: () => (
                <Box color="white" p={3} bg="#1C1C1C">
                  <Text fontWeight="bold">Congratulations</Text>
                  <Text>You can now ask your Dot question!</Text>
                </Box>
              ),
            });
          } else {
            toast({
              title: "Note Added",
              description: "Your note has been successfully saved.",
              status: "success",
              duration: 1500,
              isClosable: true,
              position: "bottom",
              render: () => (
                <Box color="white" p={3} bg="#1C1C1C">
                  <Text fontWeight="bold">Note Added</Text>
                  <Text>Your note has been successfully saved.</Text>
                </Box>
              ),
            });
          }
          navigate("/");
        }, 500);
      } else {
        // Handle non-OK responses here
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  const handleDeleteNote = async () => {
    console.log("Deleting note with ID:", noteId);

    try {
      console.log("Deleting note with ID:", noteId);
      const response = await axios.delete(`/api/notes/${noteId}`);

      if (response.status === 200) {
        // Display the toast notification
        toast({
          title: "Note Deleted",
          description: "The note has been deleted.",
          status: "success",
          duration: 2000,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Deleted</Text>
              <Text>The note has been deleted.</Text>
            </Box>
          ),
        });
        navigate("/");
      } else {
        // Handle non-OK responses here
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      console.error("Error disabling note:", error);
    }
  };

  const setNoteContent = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewNoteContent(event.target.value);
    getStatsigClient().logEvent("note_typing_changed", event.target.value);
  };

  return (
    <Box bg="#1C1C1C" minH="100vh">
      <Box
        maxWidth={sizing.maxAppContentWidthPx}
        width="100%"
        mx="auto"
        p={4}
        textAlign="left"
      >
        <Button
          leftIcon={<ChevronLeftIcon boxSize={5} left={0} right={0} p={0} />}
          alignSelf="flex-start"
          marginBottom={8}
          onClick={() => navigate(-1)}
          backgroundColor="#1C1C1C"
          color="white"
          borderRadius="md"
          _hover={{ bg: "#2C2C2C" }}
          size="md"
        >
          Back
        </Button>

        <Flex
          alignItems="flex-start"
          gap={4}
          mb={2}
          mt={4}
          bg="white"
          borderRadius="45px"
          p={4}
          _hover={{
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
            transform: "translateY(-1px)",
          }}
        >
          <Image
            src={profilePic}
            alt="Your profile picture"
            borderRadius="full"
            objectFit="cover"
            boxSize="52px"
            flexShrink={0}
          />
          <Box flex={1} position="relative">
            <AutoResizeTextarea
              placeholder="Share something about yourself..."
              value={newNoteContent}
              onChange={setNoteContent}
              onKeyDown={(e) => {
                if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
                  e.preventDefault();
                  handleSaveNote();
                }
              }}
              maxLength={2000}
              minHeight="192px"
              width="100%"
              pb={10}
              px={0}
              pt={0}
              mt={2}
              fontSize="md"
              fontWeight="bold"
              color="black"
              bg="transparent"
              border="none"
              _focus={{
                outline: "none",
                boxShadow: "none",
              }}
            />
            <Flex
              position="absolute"
              bottom={2}
              right={0}
              alignItems="center"
              gap={2}
              bg="white"
              px={2}
              py={1}
              borderRadius="full"
              zIndex={1}
            >
              <Text fontSize="sm" color="gray.500">
                {newNoteContent.length}/2000
              </Text>
            </Flex>
          </Box>
        </Flex>

        <Box
          position="fixed"
          bottom="0"
          left="0"
          right="0"
          bg="#1c1c1c"
          p={4}
          textAlign="center"
        >
          <Button
            onClick={handleSaveNote}
            maxWidth={sizing.maxAppContentWidthPx}
            width="100%"
            height="60px"
            bg="#FF0050"
            color="white"
            borderRadius="full"
            _hover={{
              bg: "#FF3371",
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
              transform: "translateY(-1px)",
            }}
            fontSize={{ base: "sm", md: "md", lg: "lg" }}
          >
            Add to Your Profile
          </Button>
        </Box>

        {noteId && (
          <Box mt={4} textAlign="center">
            <Button
              onClick={handleDeleteNote}
              backgroundColor="transparent"
              color="white"
              borderRadius="md"
              _hover={{ bg: "#2C2C2C" }}
            >
              Delete Note
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default AddOrUpdateNote;
