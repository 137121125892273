import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const MIN_NOTES_FOR_ONBOARDING_COMPLETE = 3;

export interface AuthState {
  isAuthenticated: boolean;
  userId: string | null;
  displayNameCompleted?: boolean;
  displayName?: string;
  isOnboardingComplete?: boolean;
  isLoading: boolean;
  showEnterEmailModal?: boolean;
  showLinkExpiredModal?: boolean;
  uniqId?: string;
  dateOfBirth?: string;
  profilePic?: string;
  didSeeProfilePicPage?: boolean; // local state
  isOnBoardingTutorialComplete?: boolean;
  justCompletedOnboardingTutorial?: boolean;
  numNotesOrQuestionsAnswered: number;
  currentTabIndex: number;
  onboardingRewardUserId?: string;
  createdAt?: Date;
  questionNumber: number;
  isSwitchEnabled: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  userId: null,
  isLoading: true,
  isOnboardingComplete: undefined,
  displayNameCompleted: undefined,
  showEnterEmailModal: undefined,
  showLinkExpiredModal: undefined,
  uniqId: undefined,
  dateOfBirth: undefined,
  profilePic: undefined,
  didSeeProfilePicPage: undefined,
  isOnBoardingTutorialComplete: undefined,
  justCompletedOnboardingTutorial: undefined,
  numNotesOrQuestionsAnswered: 0,
  currentTabIndex: 0,
  createdAt: undefined,
  onboardingRewardUserId: undefined,
  questionNumber: 0,
  isSwitchEnabled: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (state, action: PayloadAction<Partial<AuthState>>) => {
      return { ...state, ...action.payload };
    },
    clearAuth: (state) => {
      return { ...initialState, isLoading: false };
    },
  },
});

export const { setAuthInfo, clearAuth } = authSlice.actions;
export default authSlice.reducer;
