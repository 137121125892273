import {Box, Circle, Flex, Progress, Text} from '@chakra-ui/react'
import {CheckIcon} from '@chakra-ui/icons'
import {colors} from "../styles/colors";
import {MIN_NOTES_FOR_ONBOARDING_COMPLETE} from "../store/slices/authSlice";

const OnboardingProgressIndicator = (
    { currentStep, totalSteps = MIN_NOTES_FOR_ONBOARDING_COMPLETE }:
        {currentStep: number, totalSteps?: number}) => {
    if (currentStep >= totalSteps) {
        return null;
    }
    const remainingSteps = totalSteps - currentStep;
    return (
        <Box maxW="md" mx="auto" p={6} borderRadius="lg" width="100%">
            <Text fontWeight='bold' color="white" fontSize='3xl' mb={6} textAlign="center">
                Answer {remainingSteps} question{remainingSteps > 1 ? 's' : ''} to unlock your profile!
            </Text>

            <Flex position="relative" justify="space-between" align="center" gap={4}>
                {/* Base Progress Bar */}
                <Progress
                    value={(currentStep - 1) / (totalSteps - 1) * 100}
                    position="absolute"
                    w="100%"
                    h="2px"
                    bg="#353638"
                    colorScheme="#ffffff"
                />

                {/* Step Indicators */}
                {[...Array(totalSteps)].map((_, index) => (
                    <Circle
                        key={index}
                        size="48px"
                        bg={index < currentStep ? colors.dotsRed : '#353638'}
                        color='white'
                        zIndex={1}
                    >
                        {index < currentStep ? (
                            <CheckIcon w={6} h={6}/>
                        ) : (
                            <Box as="span" fontSize="lg" fontWeight="semibold">
                                {index + 1}
                            </Box>
                        )}
                    </Circle>
                ))}
            </Flex>
        </Box>
    )
}

export default OnboardingProgressIndicator