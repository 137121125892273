import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";

export const dotsTheme = extendTheme({
  colors: {
    dotsRed: {
      50: colors.dotsRed50,
      100: colors.dotsRed100,
      500: colors.dotsRed,
    },
  },
});
