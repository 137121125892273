import axios from "axios";
import { createStandaloneToast, useToast } from "@chakra-ui/react";
import heic2any from "heic2any";
import Compressor from "compressorjs";
import { ChangeEvent } from "react";
import { getStatsigClient } from "./statsig";

export const handleFileChange = async (
  event: ChangeEvent<HTMLInputElement>
): Promise<string | undefined> => {
  if (!event.target?.files) {
    useToast({
      title: "Error",
      description: "No file selected",
      duration: 5000,
      isClosable: true,
      position: "bottom",
    });
    return;
  }
  const file = event.target?.files[0];

  getStatsigClient()?.logEvent("upload_profile_picture");

  if (!file) {
    useToast({
      title: "Error",
      description: "No file selected",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "bottom",
    });
    return;
  }

  let imageFile = file;

  // Check if the file is HEIC and convert it
  if (file.type === "image/heic") {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1.0,
      });
      // Ensure convertedBlob is a single Blob before creating File
      const blob = Array.isArray(convertedBlob)
        ? convertedBlob[0]
        : convertedBlob;
      imageFile = new File([blob], "converted-image.jpg", {
        type: "image/jpeg",
      });
    } catch (error) {
      console.error("Error converting HEIC to JPEG:", error);
      useToast({
        title: "Error",
        description: "Error converting HEIC to JPEG",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
  }

  return await processImage(imageFile); // Now returns the URL
};

const processImage = (imageFile: File): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const { toast } = createStandaloneToast();

    reader.onload = (e) => {
      const img = new window.Image();
      img.onload = () => {
        // Calculate the center crop dimensions
        const minDimension = Math.min(img.width, img.height);
        const startX = (img.width - minDimension) / 2;
        const startY = (img.height - minDimension) / 2;

        // Create a canvas to draw the square cropped image
        const squareCanvas = document.createElement("canvas");
        squareCanvas.width = minDimension;
        squareCanvas.height = minDimension;
        const squareCtx = squareCanvas.getContext("2d");
        if (!squareCtx) {
          reject(new Error("Failed to get canvas context"));
          return;
        }

        squareCtx.drawImage(
          img,
          startX,
          startY,
          minDimension,
          minDimension,
          0,
          0,
          minDimension,
          minDimension
        );

        // Create another canvas for the circular crop
        const circularCanvas = document.createElement("canvas");
        circularCanvas.width = 200; // Target size
        circularCanvas.height = 200;
        const circularCtx = circularCanvas.getContext("2d");
        if (!circularCtx) {
          reject(new Error("Failed to get canvas context"));
          return;
        }

        // Draw a circle and clip the context
        circularCtx.beginPath();
        circularCtx.arc(100, 100, 100, 0, 2 * Math.PI, false);
        circularCtx.clip();

        // Draw the square image onto the circular canvas
        circularCtx.drawImage(squareCanvas, 0, 0, 200, 200);

        // Convert circular canvas to blob and compress it
        circularCanvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Failed to create blob"));
              return;
            }
            new Compressor(blob, {
              quality: 0.6,
              success: async (compressedBlob) => {
                try {
                  const imageUrl = await uploadProfilePicImage(compressedBlob);
                  resolve(imageUrl);
                } catch (error) {
                  reject(error);
                }
              },
              error: (err) => {
                console.error("Compression error:", err);
                toast({
                  title: "Error",
                  description:
                    "An error occurred while compressing the image. Please try again.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "bottom",
                });
                reject(err);
              },
            });
          },
          "image/jpeg",
          0.75
        );
      };
      img.src = e.target?.result as string;
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(imageFile);
  });
};

const uploadProfilePicImage = async (
  blob: Blob
): Promise<string | undefined> => {
  const { toast } = createStandaloneToast();
  const formData = new FormData();
  formData.append("profilePic", blob, "profilePic.jpg");

  try {
    const response = await axios.post(`/api/uploadProfilePic`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (!response.data) {
      toast({
        title: "Error",
        description: "An error occurred while uploading. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    const data = response.data;
    getStatsigClient().logEvent("submit_profile_picture");
    return data.imageUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    toast({
      title: "Error",
      description: "An error occurred while uploading. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "bottom",
    });
    throw error; // Re-throw the error to be caught by the calling function
  }
};
