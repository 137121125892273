import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  useToast,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { setAuthInfo } from "../../store/slices/authSlice";
import axios from "axios";
import { navigateBasedOnAuthState } from "../../utils/navigationUtils";
import { getStatsigClient } from "../../utils/statsig";

const DisplayNamePage = () => {
  const [displayName, setDisplayName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const toast = useToast();
  const maxDisplayNameLength = 20;

  useEffect(() => {
    navigateBasedOnAuthState(authState, navigate);
  }, [authState, navigate]);

  useEffect(() => {
    getStatsigClient().logEvent("view_display_name_page");
  }, []);

  useEffect(() => {
    console.log(authState.displayName);
  }, [authState.displayName]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!displayName) {
      getStatsigClient().logEvent("start_typing_display_name");
    }
    const newName = e.target.value;
    if (newName.length <= maxDisplayNameLength) {
      setDisplayName(newName);
    }
  };

  const handleSubmit = async () => {
    getStatsigClient().logEvent(
      "click_submit_display_name_button",
      displayName.trim()
    );
    if (!displayName.trim()) {
      toast({
        title: "Error",
        description: "Display name cannot be empty.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.put(`/api/user/updateDisplayName`, {
        displayName: displayName.trim(),
      });

      if (response.status === 200) {
        dispatch(setAuthInfo({ displayName: displayName.trim() }));
        navigateBasedOnAuthState(
          { ...authState, displayName: displayName.trim() },
          navigate
        );
        getStatsigClient().logEvent("submit_full_name", displayName.trim());
      } else {
        throw new Error("Failed to update display name.");
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to update display name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg="black" minH="100vh" px={4} position="relative">
      <Flex
        direction="column"
        maxW="container.sm"
        mx="auto"
        pt={12}
        minH="100vh"
      >
        <Text fontSize="4xl" fontWeight="bold" color="white" mb={2}>
          Enter name
        </Text>
        <Text fontSize="lg" color="gray.300" mb={8}>
          Add your name so your friends know who you are.
        </Text>

        <Input
          placeholder="Full Name"
          value={displayName}
          onChange={handleInputChange}
          size="lg"
          bg="white"
          color="black"
          borderRadius="full"
          height="60px"
          fontSize="md"
          _placeholder={{ color: "gray.500" }}
          mb={4}
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isLoading) {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />

        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          bg="#FF0050"
          color="white"
          size="lg"
          height="60px"
          borderRadius="full"
          _hover={{ bg: "#df0045" }}
          fontSize="md"
          fontWeight="semibold"
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
        >
          Next
        </Button>

        <Flex
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          justify="center"
          w="full"
          gap={2}
          color="#545454"
          fontSize="10px"
          fontWeight="bold"
        >
          <Link
            href="https://docs.google.com/document/d/1p3-fJ25CafAI13qyOYEZMu4rCH95JVaXRfsNejTyDD0/edit?usp=sharing"
            _hover={{ textDecoration: "underline" }}
          >
            Terms of Service
          </Link>
          <Text>•</Text>
          <Link
            href="https://docs.google.com/document/d/1zImTFVA7nfo3-ZcbCk6Hr1Y67W5P9GHEhi8PPaUAxdc/edit?usp=sharing"
            _hover={{ textDecoration: "underline" }}
          >
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DisplayNamePage;
