import {useState, useEffect} from 'react';
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import axios from "axios";
import {signOut} from "firebase/auth";
import {auth} from "../auth/firebaseConfig";
import {clearAuth} from "../store/slices/authSlice";
import {useAppDispatch} from "../store/hooks";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import logo from "../assets/logo.png";
import downArrow from "../assets/downArrow.svg"


const LogoDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogoClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        
        if (isOpen) {
            timeoutId = setTimeout(() => {
                setIsOpen(false);
            }, 3000); // 3 seconds
        }

        // Cleanup function
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isOpen]);

    const handleLogout = async () => {
        try {
            Cookies.remove("sessionToken");
            localStorage.removeItem("sessionToken");
            localStorage.removeItem("refreshToken");
            delete axios.defaults.headers.common["Authorization"];
            await signOut(auth);
            await axios.post("/api/auth/logout");
            dispatch(clearAuth());
            navigate("/signup");
        } catch (error) {
            console.error("Sign out error:", error);
        }
        setIsOpen(false);
    };

    return (
        <Box position="relative">
            <Flex gap={1}>
                <Box
                    as="img"
                    src={logo}
                    alt="dots logo"
                    height={{ base: "32px" }}
                    width="auto"
                />
                <Image
                    src={downArrow}
                    alt="Down arrow"
                    width="12px"
                    onClick={handleLogoClick}
                    className="transition-opacity hover:opacity-80"
                    cursor="pointer"
                />
            </Flex>

            {isOpen && (
                <Box
                    position="absolute"
                    top="100%"
                    left="0"
                    mt="2"
                    py="4"
                    px="2"
                    bg="#4F5156"
                    rounded="lg"
                    shadow="lg"
                    zIndex="50"
                    paddingX={5}
                >
                    <Text
                        style={{textWrap: 'nowrap'}}
                        textColor="red"
                        onClick={handleLogout}
                    >
                        Log out
                    </Text>
                </Box>
            )}
        </Box>
    );
};

export default LogoDropdown;