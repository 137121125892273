import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import MainPage from "./components/MainPage";
import LandingPage from "./components/LandingPage";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AskLink from "./components/AskLink";
import DisplayNamePage from "./components/onboarding/DisplayNamePage";
import PrivateRoute from "./components/PrivateRoute";
import { StatsigProvider } from "@statsig/react-bindings";
import BirthdayPage from "./components/onboarding/BirthdayPage";
import { AuthProvider } from "./auth/AuthContext";
import ProfilePicUpload from "./components/ProfilePicUploadPage";
import { getStatsigClient, statsigSDKKey } from "./utils/statsig";
import AskDetails from "./components/AskDetails";
import AddOrUpdateNote from "./components/AddOrUpdateNote";
import { dotsTheme } from "./styles/theme";

function AppContent() {
  const client = getStatsigClient();

  return (
    <StatsigProvider client={client} sdkKey={statsigSDKKey}>
      <ChakraProvider theme={dotsTheme}>
        <Router>
          <Box
            className="App__Container"
            minHeight="100vh"
            backgroundColor="#1C1C1C"
          >
            <Routes>
              <Route path="/signup" element={<LandingPage />} />
              <Route path="/profile/:uniqId" element={<AskLink />} />
              <Route element={<PrivateRoute />}>
                <Route
                  path="/completeDisplayName"
                  element={<DisplayNamePage />}
                />
                <Route path="/birthday" element={<BirthdayPage />} />
                <Route
                  path="/profilePicUpload"
                  element={<ProfilePicUpload />}
                />
                <Route path="/ask/:askId" element={<AskDetails />} />
                <Route path="/" element={<MainPage />} />
                <Route path="/addNote" element={<AddOrUpdateNote />} />
                <Route path="/notes/:noteId" element={<AddOrUpdateNote />} />
              </Route>
            </Routes>
          </Box>
        </Router>
      </ChakraProvider>
    </StatsigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Provider>
  );
}

export default App;
