import React from "react";
import { Box, BoxProps, Flex, Image, Text } from "@chakra-ui/react";
import checkIcon from "../assets/checkIconWhite.png";
import checkIconBlack from "../assets/checkIconBlack.png";
import { useNavigate } from "react-router-dom";
import defaultProfilePic from "../assets/profileAnonPicInverse.png";
import { getStatsigClient } from "../utils/statsig";
import { useSelector } from "react-redux";
import type { RootState } from "../store/store";

interface InboxItemProps extends Omit<BoxProps, "onClick"> {
  /** The name of the person asking the question */
  askerName: string;
  /** URL of the asker's profile picture */
  askerProfilePic: string;
  /** The question or note text */
  title: string;
  /** Whether the question has been viewed */
  isViewed: boolean;
  /** Whether the question has been replied to */
  isReplied: boolean;
  isNote: boolean;
  id: string; // Either note or ask id
  isNuxQuestion: boolean;
  askerId: string;
  receiverName?: string;
}

const InboxItem: React.FC<InboxItemProps> = ({
  askerName,
  askerProfilePic,
  title,
  isViewed,
  isReplied,
  isNote,
  id,
  isNuxQuestion,
  askerId,
  receiverName,
}) => {
  const navigate = useNavigate();
  const authState = useSelector((state: RootState) => state.auth);
  const onClick = () => {
    if (isNote) {
      getStatsigClient().logEvent("click_note_inbox_item", undefined, { id });
      navigate(`/notes/${id}`);
    } else {
      if (isNuxQuestion) {
        getStatsigClient().logEvent("click_nux_question_in_feed", undefined, {
          id,
        });
      } else if (askerId === authState.userId && receiverName === "Yourself") {
        getStatsigClient().logEvent(
          "click_internal_question_in_feed",
          undefined,
          {
            id,
          }
        );
      } else if (askerId === authState.userId && receiverName !== "Yourself") {
        getStatsigClient().logEvent(
          "click_external_question_in_feed",
          undefined,
          { id }
        );
      } else {
        getStatsigClient().logEvent(
          "click_question_being_asked_in_feed",
          undefined,
          {
            id,
          }
        );
      }

      navigate(`/ask/${id}`);
    }
  };

  const isOutgoingQuestion =
    askerId === authState.userId && receiverName !== "Yourself";
  const shouldBeDark = isNote || ((isReplied) && !isOutgoingQuestion);

  const textColor = shouldBeDark ? "white" : "#1C1C1C";
  const backgroundColor = shouldBeDark ? "#1C1C1C" : "white";
  const borderColor = shouldBeDark ? "#545454" : "white";
  return (
    <Box
      bg={backgroundColor}
      borderRadius="45px"
      borderWidth="0.25px"
      borderColor={borderColor}
      w="calc(100% - 16px)"
      maxW="568px"
      p={4}
      position="relative"
      cursor="pointer"
      transition="background-color 0.2s"
      onClick={onClick}
      role="button"
      tabIndex={0}
      overflow="hidden"
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
      _hover={{
        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
        transform: "translateY(-1px)",
      }}
    >
      <Flex alignItems="center" gap={4}>
        {!isViewed ? (
          <Box
            w="15px"
            h="15px"
            top="12px"
            right="34px"
            position="absolute"
            borderRadius="full"
            bg="#FF0050"
            aria-label="unread question"
          />
        ) : (
          isReplied && (
            <Image
              w="20px"
              h="20px"
              top="12px"
              right="34px"
              position="absolute"
              src={isOutgoingQuestion ? checkIconBlack : checkIcon}
              alt="Feedback given icon"
            />
          )
        )}
        <Image
          src={askerProfilePic || defaultProfilePic}
          alt={`${askerName}'s profile picture`}
          borderRadius="full"
          objectFit="cover"
          boxSize="52px"
          flexShrink={0}
        />

        <Box flex={1}>
          {/* Username and "asked:" */}
          <Flex alignItems="center" justifyContent="space-between" gap={1}>
            <Flex alignItems="center" gap={1}>
              <Text color={"#FF0050"} fontWeight="bold" fontSize="small">
                {isNote ? "You" : askerName}
              </Text>
              <Text color={textColor} fontSize="small">
                {isNote ? "added a note:" : "asked"}
              </Text>

              <Text color={"#FF0050"} fontWeight="bold" fontSize="small">
                {receiverName ? receiverName : ""}
              </Text>
            </Flex>
          </Flex>

          <Text
            color={textColor}
            fontWeight="bold"
            textAlign="left"
            noOfLines={2}
            minHeight="3rem"
          >
            {title}
          </Text>
        </Box>

        <Text fontSize="35px" aria-hidden="true" color={textColor}>
          ›
        </Text>
      </Flex>
    </Box>
  );
};

export default InboxItem;
