import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import OnboardingProgressIndicator from "./OnboardingProgressIndicator";
import shareImage from "../assets/shareImageInstagram.png";
import shareImage2 from "../assets/shareImageMessages.png";
import shareImage3 from "../assets/shareImageHinge.png";
import shareImage4 from "../assets/shareImageBumble.png";
import shareImage5 from "../assets/shareImageTinder.png";
import { setAuthInfo } from "../store/slices/authSlice";
import { shareProfile } from "../utils/shareUtils";
import { getStatsigClient } from "../utils/statsig";
import { sizing } from "../styles/sizing";

const ShareTab = () => {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setSelectedOption] = React.useState<number | null>(
    null
  );

  const shareOptions = [
    {
      type: "Instagram",
      image: shareImage,
      instructions: [
        "Copy your profile link",
        "Open Instagram",
        "Paste the link in your bio",
        "Share with your followers",
      ],
    },
    {
      type: "Messages",
      image: shareImage2,
      instructions: [
        "Copy your profile link",
        "Open Messages",
        "Share with your dates or partners",
        "Start a conversation",
      ],
    },
    {
      type: "Hinge",
      image: shareImage3,
      instructions: [
        "Copy your profile link",
        "Open Hinge",
        "Paste the link in your bio",
        "Share the link with your matches",
      ],
    },
    {
      type: "Bumble",
      image: shareImage4,
      instructions: [
        "Copy your profile link",
        "Open Bumble",
        "Paste the link in your bio",
        "Share the link with your matches",
      ],
    },
    {
      type: "Tinder",
      image: shareImage5,
      instructions: [
        "Copy your profile link",
        "Open Tinder",
        "Paste the link in your bio",
        "Share the link with your matches",
      ],
    },
  ];

  const handleOptionClick = (index: number) => {
    setSelectedOption(index);
    onOpen();
  };

  const handleShareAndCopy = async () => {
    const userId = authState.userId;
    if (!userId) {
      console.error("No userId found");
      return;
    }
    getStatsigClient().logEvent("click_share_link");

    if (authState.uniqId) {
      await shareProfile(authState.uniqId, toast);
    }
  };

  const handleGetStartedClick = async () => {
    dispatch(setAuthInfo({ currentTabIndex: 0 }));
  };

  return (
    <div>
      <Flex
        direction="column"
        alignItems="center"
        gap={4}
        mb={100}
        minHeight="calc(100vh - 200px)"
      >
        {!authState.isOnBoardingTutorialComplete && (
          <OnboardingProgressIndicator
            currentStep={authState.numNotesOrQuestionsAnswered}
          />
        )}

        {authState.isOnBoardingTutorialComplete && (
          <>
            <Text
              fontWeight="bold"
              color="white"
              fontSize="3xl"
              mb={2}
              textAlign="center"
            >
              Share your link!
            </Text>
            <Text
              fontWeight="bold"
              color="white"
              fontSize="lg"
              mb={4}
              textAlign="center"
            >
              Take the first step to a meaningful connection
            </Text>
          </>
        )}

        <Flex
          direction="column"
          align="center"
          w="calc(100% - 16px)"
          maxW="550px"
          px={4}
          gap={6}
        >
          {shareOptions.map((option, index) => (
            <Button
              key={index}
              width="100%"
              p={4}
              height={{ base: "56px", md: "58px", lg: "60px" }}
              onClick={() => handleOptionClick(index)}
              bg="white"
              borderRadius="45px"
              _hover={{
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
                transform: "translateY(-1px)",
              }}
            >
              <Text color="black" fontSize="lg">
                {option.type}
              </Text>
            </Button>
          ))}
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} size="lg">
          <ModalOverlay bg="blackAlpha.900" borderRadius="30px" />
          <ModalContent bg="#1c1c1c" color="white" borderRadius="30px">
            <ModalCloseButton />
            <ModalHeader mt={6} textAlign="center">
              {selectedOption !== null && shareOptions[selectedOption].type}
            </ModalHeader>
            <ModalBody pb={6}>
              {selectedOption !== null && (
                <Flex direction="column" align="center">
                  {shareOptions[selectedOption].instructions.map(
                    (instruction, idx) => (
                      <Flex
                        key={idx}
                        width="100%"
                        maxW="300px"
                        mb={3}
                        align="center"
                        alignSelf="center"
                      >
                        <Box
                          mr={4}
                          bg="#FF0050"
                          borderRadius="full"
                          w={6}
                          h={6}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text fontWeight="bold">{idx + 1}</Text>
                        </Box>
                        <Text>{instruction}</Text>
                      </Flex>
                    )
                  )}

                  <Image
                    src={shareOptions[selectedOption].image}
                    alt="Share Instructions"
                    height="auto"
                    maxHeight="50vh"
                    width="100%"
                    mb={6}
                    mt={6}
                    objectFit="contain"
                  />
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        bg="#1c1c1c"
        p={4}
        textAlign="center"
      >
        <Button
          width="100%"
          maxW={sizing.maxAppContentWidthPx}
          mx="auto"
          onClick={
            authState.isOnBoardingTutorialComplete
              ? handleShareAndCopy
              : handleGetStartedClick
          }
          backgroundColor={{ base: "#FF0050", md: "#FF0050", lg: "#FF0050" }}
          color={{ base: "#white", md: "white", lg: "white" }}
          height={{ base: "56px", md: "58px", lg: "60px" }}
          borderRadius={{ base: "30px", md: "30px" }}
          _hover={{ bg: "#ef004b" }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          {authState.isOnBoardingTutorialComplete
            ? "Share Your Link"
            : "Get Started"}
        </Button>
      </Box>
    </div>
  );
};

export default ShareTab;
