
const questions = [
    'What are your favorite books?',
    'What are your favorite songs?',
    'What are your favorite movies?',
    'What are your favorite TV shows?',
    'What are your favorite games to play?',
    'What are your favortie podcasts?',
    'What music artists do you like the most?',
    'What foods or restaurants do you enjoy?',
    'What is your ideal weekend routine?',
    'What are your favorite restaurants?',
    'What are your favorite foods?',
    'What are your favorite cities?',
    'When was your last relationship?',
    'Are you in a relationship?',
    'What do you look for in a partner?',
    'Do you believe in love?',
    'What is your favorite sport?',
    'What is your favorite place to go on vacation?',
    'Do you prefer the sea or the mountain?',
    'Do you enjoy your work?',
    'What are your goals for your career?',
    'What makes you happy?',
    'What are your favorite foods?',
    'What movies have you seen more than 2 times?',
    'What is your favorite season?',
    'What is your favorite holiday?',
    'What do you think is the purpose of life?',
    'What do you value most: success, relationships, freedom, or security? Why?',
    'What makes you happy?',
    'If you had unlimited money and time, what would you do?',
    'If you could have dinner with any three people, dead or alive, who would you pick?',
    'How would your best friend describe you?',
    'What was your very first job?',
    'What is the last book that you couldn’t put down?',
    'If you could sit down with your 15-year old self, what would you tell yourself?',
    'Do you follow rules or do you break them?',
    'What do you look for in a friend or partner?',
    'What is an early memory that always makes you smile?',
    'Who is the smartest person you know?',
    'Did you ever steal anything?',
    'What is a simple pleasure of yours?',
    'What is your favorite smell or scent?',
    'What are your favorite cities?',
    'Where do you live?',
    'What is your dream place to live?',
    'When was your last relationship?',
    'What do you look for in a partner?',
    'Do you believe in love?',
    'What are your goals for your career?',
    'Do you have any pets?',
    'What pet do you want to have?',
    'What did you study?',
    'What do you do for work?',
    'What do you like to do in your free time?',
    'Do you like tattoos? Do you have any?',
    'Where were you born?',
    'If you could have any superpower, which one would you have any why?',
    'What is your horoscope?',
    'What is your personality type?',
    'What is your Enneagram type?',
    'What is your MBTI type?',
    'Tell me a joke?',
    'What is your favorite color?',
    'What is your favorite animal?',
    'Roast yourself',
    'Are you an introvert or extrovert?',
    'What is your favorite app on your phone?',
    'What is the biggest lie you have ever told?',
    'What is the best gift you have ever received?',
    'What is the biggest mistake you have made?',
    'What is the most adventurous thing you have done?',
    'What is your biggest fear?',
    'When did you have your first kiss?',
    'What’s the most attractive physical feature?',
    'What are your icks?',
    'Do you smoke?',
    'Do you drink?',
    'Do you do drugs?',
];

export const pickRandomQuestion = () => {
    const randomIndex = Math.floor(Math.random() * questions.length);
     return questions[randomIndex];
}